(function($){

    $(function(){
        $(window).on('load', getTestJSON);
		$(window).on('scroll',shrinkHeader);
    });

	function shrinkHeader(){
		if( $(document).scrollTop() < 120 ){
			$('.nav').removeClass('small');
		} else {
			$('.nav').addClass('small');
		}
	}

    function getTestJSON(){
        $.getJSON("eft_json.json", function(json) {
            localStorage.setItem('years', JSON.stringify(json));

            $("#timeline-active-year-active-trip").css("pointer-events", "none");

            for (var i = 0; i < json.length; i++){
                setYearHeaderHTML(json, i);
            }
        });
    }

	$('header nav a').on('click',function(){
		var headerHeight = $('header').height();
		var sectionPosition = $($(this).attr('href')).offset().top;

		if( !$('.nav').hasClass('small') ){
			headerHeight -= 77;
		}

		if( $(this).attr('href') == 'index.html' ){
			headerHeight = sectionPosition = 0;
		}

		$('body, html').animate({
			scrollTop: sectionPosition - headerHeight
		}, 1000);
		return false;
	});


    $('ul').on('click','.year', function(){
        $("#timeline-active-year-active-trip").css("pointer-events", "none").removeClass("active");

        var allYears = JSON.parse(localStorage.getItem('years'));
        var year = parseInt($(this).context.textContent);
        var trips = getYear(allYears, year).trips;
        localStorage.setItem('trips', JSON.stringify(trips));

        $("#currentYear").removeClass("current").removeAttr("id");
        $(this).addClass("current").attr("id", "currentYear");

        $("div").remove("#timeline-active-year-text");
        $("<div id='timeline-active-year-text'>"+year+"</div>").appendTo("#timeline-active-year-count");

        setYearTripsHTML(trips);
    });


    $('#timeline-active-year-trip-list').on('click', '.current-trips-list-item', function(){
        $("#timeline-active-year-active-trip").css("pointer-events", "auto").addClass("active");
        var currentTrips = JSON.parse(localStorage.getItem('trips'));
        var trip = getTrip(currentTrips, $(this).context.textContent);

        removeOldSingleTripHTML();
        setSingleTripHTML(trip);
    });

    $("#timeline-active-year-back-button").on('click', function(){
        $("#timeline-active-year-active-trip").css("pointer-events", "none").removeClass("active");
    });


	$(window).on('resize', compensateForFixedHeader);

	function compensateForFixedHeader(){
		$('body').css('padding-top',$('header').height());
	}

	compensateForFixedHeader();


    ///// Private Functions /////

    function setYearHeaderHTML(json, i){
        var year = json[i].year;
        var yearHTML = "<li class='year'>"+year+"</li>";
        if (i == 0){
            localStorage.setItem('trips', JSON.stringify(json[i].trips));
            yearHTML = "<li class='current year' id='currentYear'>"+year+"</li>";
            setDefaultYearTripsHTML(year, json, i);
        }
        $(yearHTML).appendTo("#timeline-menu ul");
    }

    function setDefaultYearTripsHTML(year, json, i){

        $("<div id='timeline-active-year-text'>"+year+"</div>").appendTo("#timeline-active-year-count");

        for (var j = 0; j < json[i].trips.length; j++){
            var trip = json[i].trips[j];
            var tripListHTML = "<li class='current-trips-list-item'>"+trip.name+"</li>";
            $(tripListHTML).appendTo("#timeline-active-year-trip-list ul");
        }
    }

    function setYearTripsHTML(trips){
        $('li').remove(".current-trips-list-item");

        for (var j = 0; j < trips.length; j++){
            var trip = trips[j];
            var tripListHTML = "<li class='current-trips-list-item'>"+trip.name+"</li>";
            $(tripListHTML).appendTo("#timeline-active-year-trip-list ul");
        }
    }

    function removeOldSingleTripHTML(){
        $('h1').remove("#current-active-trip-header");
        $('h2').remove("#current-active-trip-subtitle");
        $('li').remove(".current-active-trip-info-list-item");
        $('div').remove(".content");
    }

    function setSingleTripHTML(trip){
        var tripHeaderHTML = "<h1 id='current-active-trip-header'>"+trip.name+"</h1>";
        $(tripHeaderHTML).appendTo("#timeline-active-year-header");

		if( trip.subtitle ){
			var tripHeaderHTML = "<h2 id='current-active-trip-subtitle'>"+trip.subtitle+"</h1>";
			$(tripHeaderHTML).appendTo("#timeline-active-year-header");
		}

		var tripInfoHTML = '';
		if( trip.date ){
			tripInfoHTML += '<li class="current-active-trip-info-list-item"><h3>Broadcast Date: <time>'+trip.date+'</time></h3></li>';
		}
		if( trip.time ){
			tripInfoHTML += '<li class="current-active-trip-info-list-item"><h3>'+trip.time+'</h3></li>';
		}
		if( trip.grades ){
			tripInfoHTML += '<li class="current-active-trip-info-list-item"><h3>Grades: '+trip.grades+'</h3></li>';
		}
        $(tripInfoHTML).appendTo("#timeline-active-year-active-trip ul");

        var tripContent = "<div class='content'>"+trip.content+"</div>";
        $(tripContent).appendTo("#timeline-active-year-active-trip .single-trip-content");

		$('#timeline-active-year-active-trip').scrollTop(0);
    }

    function getYear(allYears, year){
        for (var i = 0; i < allYears.length; i++){
            if (allYears[i].year == year){
                return allYears[i];
            }
        }
    }

    function getTrip(allTrips, tripName){
        for (var i = 0; i < allTrips.length; i++){
            if(allTrips[i].name == tripName){
                return allTrips[i];
            }
        }
    }

})(jQuery);